@keyframes fadeup-in {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.trophy-wrap {
  width: 100%;
  height: 35vh;
  position: relative;
}

.trophy {
  max-width: 150px;
  margin: 0 auto;
  width: 33%;
  height: 100%;
  position: relative;
  opacity: 0;
  padding-top: 25px;
  animation: fadeup-in 0.25s ease 0.15s forwards;
}

.dots {
  div {
    max-width: 15px;
    max-height: 15px;
    width: 3vw;
    height: 3vw;
    position: absolute;
    border-radius: 100%;
    background-color: blue;
    top: 5%;
    left: 17%;
    opacity: 0;
    animation: fadeup-in 0.5s ease 0.5s forwards;

    &:nth-of-type(2n) {
      background-color: red;
    }

    &:nth-of-type(3n) {
      background-color: orange;
    }

    &:nth-of-type(2) {
      left: 23%;
      top: 45%;
    }

    &:nth-of-type(3) {
      left: 9%;
      top: 75%;
    }

    &:nth-of-type(4) {
      left: 65%;
      top: 75%;
    }

    &:nth-of-type(5) {
      left: 93%;
      top: 48%;
    }

    &:nth-of-type(6) {
      left: 72%;
      top: 10%;
    }
  }
}

.squiggles {
  height: 100%;
  pointer-events: none;

  div {
    position: absolute;
    max-width: 30px;
    max-height: 15px;
    width: 7vw;
    height: 4vw;
    left: 5%;
    top: 45%;
    opacity: 0;
    animation: fadeup-in 0.5s ease 0.5s forwards;

    span {
      background-image: url(/assets/images/goal-complete/squiggle-blue.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transform: rotate(-93deg);
      width: 100%;
      height: 100%;
      display: block;
    }

    &:nth-of-type(2n) {
      animation: fadeup-in 0.5s ease 0.25s forwards;

      span {
        background-image: url(/assets/images/goal-complete/squiggle-orange.svg);
      }
    }

    &:nth-of-type(3n) {
      animation: fadeup-in 0.5s ease 0.35s forwards;

      span {
        background-image: url(/assets/images/goal-complete/squiggle-red.svg);
      }
    }

    &:nth-of-type(2) {
      left: 15%;
      top: 25%;

      span {
        transform: rotate(-43deg);
      }
    }

    &:nth-of-type(3) {
      left: 29%;
      top: 3%;

      span {
        transform: rotate(4deg);
      }
    }

    &:nth-of-type(4) {
      left: 78%;
      top: 53%;

      span {
        transform: rotate(86deg);
      }
    }

    &:nth-of-type(5) {
      left: 65%;
      top: 45%;

      span {
        transform: rotate(66deg);
      }
    }

    &:nth-of-type(6) {
      left: 85%;
      top: 14%;

      span {
        transform: rotate(16deg);
      }
    }
  }
}

.left {
  .callout-graphic {
    transform: rotate(90deg);
    top: -45px;
    left: -110px;
    .location-icon {
      transform: rotate(-90deg);
      transform-origin: 82% 28%;
    }
  }
}

.callout-graphic {
  position: absolute;
  left: -130px;
  top: 10px;
}

.phone-wrapper {
  .phone-bg {
    width: 100%;
    padding-top: 213%;
    background-image: url('/assets/images/welcome/phone-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
  .phone-screen {
    position: absolute;
    top: 0;
    width: 90%;
    height: 90%;
    margin: 10% 5%;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    &-content {
      padding-top: 5px;
      &-loader {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
      }
    }
  }
}

.mask-tools-back {
  // left: 2%;
  mask-image: url('/assets/images/welcome/mask-tools-back.svg');
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: top left;
  padding: 32% 0;
  position: relative;
  width: 100%;
  z-index: 0;
}

.mask-tools-front {
  // left: 2%;
  mask-image: url('/assets/images/welcome/mask-tools-front.svg');
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: top left;
  padding: 31% 0;
  position: absolute;
  top: -2%;
  width: 100%;
  z-index: 2;
}

.hero-hash-circle-small-container {
  left: -10%;
  position: absolute;
  top: -30%;
  width: 100%;
  z-index: 1;
  .hero-hash-circle-small {
    background-image: url('/assets/images/welcome/hash-circle-small.svg');
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 100%;
    width: 100%;
  }
}

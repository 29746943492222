.hero-hash-circle-container {
  width: 45%;
  position: absolute;
  top: 4%;
  right: 7%;
  .hero-hash-circle {
    width: 100%;
    padding-top: 100%;
    background-image: url('/assets/images/welcome/hash-circle.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.confidential {
  &:before {
    content: '';
    width: 14px;
    height: 16px;
    position: absolute;
    left: -0px;
    top: 6px;
    opacity: 0.5;
  }
  .confidential_arrow_svg {
    position: absolute;
    top: -50px;
    left: 305px;
    width: 30px;
    path {
      fill: transparent;
      stroke-width: 3;
      stroke-dasharray: 400;
      stroke-dashoffset: 400;
      animation-duration: 3s;
      animation-fill-mode: forwards;
      animation-name: draw;
      animation-delay: 2s;
      &.arrows {
        animation-delay: 2.5s;
      }
    }
    @keyframes draw {
      to {
        stroke-dashoffset: 0;
      }
    }
  }
}

.mask-hero-sponsor-tag {
  background-size: cover;
  background-position: top center;
  width: 260px;
  height: 110px;
  border-top-left-radius: 2px;
  border: solid 12px white;
  margin-right: 1px;
  position: absolute;
  right: -1px;
  bottom: 0;
  @media (max-width: 425px) {
    width: 45%;
    height: 25%;
  }
}

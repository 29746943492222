.hash-square-container {
  width: 100%;
  position: absolute;
  top: -10%;

  .hash-square {
    width: 100%;
    padding-top: 100%;
    background-image: url('/assets/images/welcome/hash-square.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
  }
}

.mask-quotes-bottom {
  mask-image: url('/assets/images/welcome/mask-quotes.svg');
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: top center;
  width: 100%;
  float: right;
  padding-top: 100%;
  margin-top: 3%;
  margin-right: -10px;
}

.mask-quotes-top {
  mask-image: url('/assets/images/welcome/mask-quotes.svg');
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: top center;
  background-size: cover;
  background-position: top center;
  width: 100%;
  position: absolute;
  right: -25px;
  top: 0;
  padding-top: 100%;
}

@font-face {
  font-family: 'mark-ot';
  src:
    url('./fonts/MarkOT.woff'),
    url('./fonts/MarkOT.ttf') format('truetype');
}

@font-face {
  font-family: 'mark-ot-medium';
  src:
    url('./fonts/MarkOT-Medium.woff'),
    url('./fonts/MarkOT-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'mark-ot-bold';
  src:
    url('./fonts/MarkOT-Bold.woff'),
    url('./fonts/MarkOT-Bold.ttf') format('truetype');
}

* {
  scroll-behavior: smooth;
}

html,
body {
  background-color: #fbfbfb;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
